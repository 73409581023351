import React from "react";
import { Link } from "gatsby";
import GlobalLayout from "../components/global/GlobalLayout";
import ArrowRightSVG from "../global/assets/arrow-right.svg";
import "./404.scss";

interface IPageNotFoundProps {
  error?: string;
}

const genericErrorMessage: string = "Looks like you've come across an empty page.";

const PageNotFound = ({ error = genericErrorMessage }: IPageNotFoundProps) => {
  return (
    <GlobalLayout siteMetadata={{ name: "404 Page Not Found | Cro Metrics Support Wiki", description: "No page content found" }}>
      <div className="PageNotFound">
        <h1 className="PageNotFound__error">404: Page not found</h1>
        <div className="PageNotFound__details">{error}</div>
        <Link to="/">
          <span>Don't worry, here's a safe way back</span>
          <ArrowRightSVG/>
        </Link>
      </div>
    </GlobalLayout>
  );
};
export default PageNotFound;